import '../css/App.css';
import Navbar2023 from './Navbar2023';
import Tutorial from './Utilities/Tutorial';
import AgentTutorial from './Utilities/AgentTutorial';
import Rankings from './Utilities/Rankings';
import FinalRankings from './Admin/FinalRankings';
import ManualScore from './Admin/ManualScore';
import UpdateQuestions from './Admin/UpdateQuestions';
import Login from './User/Login';
import AdminLogin from './Admin/AdminLogin';
import Admin from './Admin/Admin';
import Quiz from './User/Quiz';
import { Routes, Route } from 'react-router-dom';

function VCCHome() {

  return (
      <div className="App">

        <Navbar2023/>
        <Routes>
          <Route path="Login" element={<Login/>} />
          <Route path="AdminLogin" element={<AdminLogin/>} />
          <Route path="Admin" element={<Admin/>} />
          <Route path="Tutorial" element={<Tutorial/>} />
          <Route path="AgentTutorial" element={<AgentTutorial/>} />
          <Route path="Rankings" element={<Rankings/>} />
          <Route path="FinalRankings" element={<FinalRankings/>} />
          <Route path="ManualScore" element={<ManualScore/>} />
          <Route path="UpdateQuestions" element={<UpdateQuestions/>} />
          <Route path="Quiz" element={<Quiz/>} />
          {/* Define other routes here */}
        </Routes>
      </div>
  );
}

export default VCCHome;