import React, { useEffect } from 'react';
import './css/tutorial.css'
import PureMarkdown from './PureMarkdown';

const content = `
# Agent Tutorial

This tutorial will show you how to submit an agent to play the Greedy Pig Game.

## Prerequisites:
Requests library needs to be installed in your local Python environment.

## Step 1: Test game server

\`\`\`python
import requests

response = requests.get('https://agents.vccfinal.net/')

if response.status_code == 200:
    print("Success, we are ready to go!")
else:
    print("An error has occurred.")
\`\`\`

Your Python terminal will print: "Success, we are ready to go!". Proceed to Step 2.

## Step 2: Submit your agent

To submit your agent, write all your code inside the \`CustomPlayer\` class as indicated. You can also add any Python imports you want to use.

\`\`\`python
import requests
import inspect

class CustomPlayer():

    def make_decision(self, game_state):
        # You can import random here if you wish to use it

        # Change this algorithm. You must return 'bank' or 'continue'.
        if game_state['unbanked_money'][self.name] >= 20:
            return 'bank'
            
        return 'continue'


url = 'https://agents.vccfinal.net/submit_agent/'
code = inspect.getsource(CustomPlayer)
data = {"team_name": "TEAMNAME", "password": "TEAMPASSWORD", "code": code}

response = requests.post(url, json=data)
if response.status_code == 200:
    print(response.json()["game_result"])
else:
    print("An error has occurred.")
\`\`\`

### How to code your agent

The dummy agent always banks when it reaches $20 unbanked.  
To improve on this, use the **game_state** dictionary in a smarter way. Make your decision by returning 'bank' or 'continue' from the \`make_decision\` function.

Here's a sample of what the **game_state** dictionary looks like during a game:

\`\`\`json
{
    "round_no": 9,
    "roll_no": 5,
    "players_banked_this_round": [
      "Sanjin",
      "Larissa"
    ],
    "banked_money": {
      "Sanjin": 93,
      "Nathan": 78,
      "Jen": 58,
      "Larissa": 93,
      "Danijela": 74
    },
    "unbanked_money": {
      "Sanjin": 0,
      "Nathan": 22,
      "Jen": 22,
      "Larissa": 0,
      "Danijela": 18
    }
}
\`\`\`

In the game above, we can see that Sanjin and CustomPlayer have banked their money and everyone else is still in play. Every round, your function runs and has access to all this information. You just need to decide whether to continue and/or bank.

### Know your rankings

The agent below only banks when it is in the top 3 (using **self.my_rank(game_state)**). It might not be the best strategy on its own, but it's something to consider:

\`\`\`python
class Mr_Top3(Player):
    def make_decision(self, game_state):
        my_ranking = self.my_rank(game_state)
        if my_ranking <= 3:
            return 'bank'
        return 'continue'
\`\`\`

### Your own Simulator

Use this link to try out different agents against each other: [https://replit.com/@SanjinDedic/agentSIMX#main.py](https://replit.com/@SanjinDedic/agentSIMX#main.py)

## Rules of Greedy Pig:

1. **Game Rounds**:
    - Each round begins with a roll of a dice.
    - If the dice roll is 1, the round ends immediately, and all players lose their unbanked money for that round.
    - If the dice roll is 2 to 6, each player gets a chance to make a decision.
  
2. **Player Decisions**:
    - Players choose whether to 'bank' the money from the current roll or not.
    - Banking the money adds the current roll's value to their 'banked money' and resets their 'unbanked money' to zero.
    - Not banking means the roll's value is added to their 'unbanked money'.

3. **Game State**:
    - The game maintains a state that includes each player's banked and unbanked money.
    - Players base their decisions on this game state.

4. **End Condition**:
    - The game continues until a player banks a total of 100 points or more.
    - The player with the highest banked money at this point is declared the winner.

5. **Ranking and Points Assignment**:
    - After the game ends, players are ranked based on their banked money.
    - Points are assigned based on the rankings, with potential adjustments for ties.
    - The maximum points are 5, which will be given to the player with the most banked money. If they are tied with someone else, they will receive reduced points (4 each). The same applies to each position's points. The minimum points are 0.
    `

const AgentTutorial = () => {
  return (
    
    <PureMarkdown content={content}/>

  );
}

export default AgentTutorial;