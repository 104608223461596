import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkTokenExpiry } from '../../slices/authSlice';
import CreateTeam from './CreateTeam';
import ResetData from './ResetData';
import ResetQuestion from './ResetQuestion';
import SetJson from './SetJson';
import UploadFile from './UploadFile';
import ManualScore from './ManualScore';


function Admin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiUrl = useSelector((state) => state.auth.ApiUrl);
    const token = useSelector((state) => state.auth.token);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    useEffect(() => {
      const tokenExpired = dispatch(checkTokenExpiry());
      if (!isAuthenticated || currentUser.role !== "admin" || tokenExpired) {
        // Redirect to the home page if not authenticated
        navigate('/VCC/AdminLogin');
      }
      
    }, [navigate]);

    


    return (
        <div className="main-container">
            <CreateTeam API_URL={apiUrl} accessToken={token}/>
            <ResetData API_URL={apiUrl} accessToken={token}/>
            <ResetQuestion API_URL={apiUrl} accessToken={token}/>
            <SetJson API_URL={apiUrl} accessToken={token}/>
            <UploadFile API_URL={apiUrl} accessToken={token}/> 
                
      </div>
      
    );
    
  }

export default Admin;