import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { checkTokenExpiry } from '../../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './css/manualscore.css'


function ManualScore() {

    const [teams, setTeams] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const API_URL = useSelector((state) => state.auth.ApiUrl);
    const token = useSelector((state) => state.auth.token);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    useEffect(() => {
        const tokenExpired = dispatch(checkTokenExpiry());
        if (!isAuthenticated || currentUser.role !== "admin" || tokenExpired) {
          // Redirect to the home page if not authenticated
          navigate('/VCC/AdminLogin');
        }else{
            fetch(`${API_URL}/manual_questions`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                }
              })
                .then(response => response.json())
                .then(data => {
          
                  if (data.status === "success") {
                    setTeams(data.data);
                  } else if (data.status === "failed") {
                    toast.error(data.message, {
                        position: "top-center"
                    });
                  }
                })
                .catch(error => {
                    console.log(error);
                    toast.error(`Failed to get data`, {
                        position: "top-center"
                    });
                });
        }
        
      }, [navigate]);
      
        
          const fetchAllScores = () => {
            return teams.map(team => ({
              team_name: team.team_name,
              scores: {
                q1_score: parseInt(team.q1_score) || 0,
                q2_score: parseInt(team.q2_score) || 0,
                q3_score: parseInt(team.q3_score) || 0,
                q4_score: parseInt(team.q4_score) || 0,
                q5_score: parseInt(team.q5_score) || 0,
              }
            }));
          };

        const updateScores = () => {
          const allScores = fetchAllScores();
          fetch(`${API_URL}/update_manual_score`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ teams: allScores }),
          })
            .then(response => response.json())
            .then(data => {
      
              if (data.status === "success") {
                toast.success(data.message, {
                    position: "top-center"
                });
              } else if (data.status === "failed") {
                toast.error(data.message, {
                    position: "top-center"
                });
              }
            })
            .catch(error => {
                console.log(error);
                toast.error(`Failed to add Team`, {
                    position: "top-center"
                });
            });
        };

        const handleScoreChange = (teamIndex, questionKey, value) => {
            const updatedTeams = [...teams];
            updatedTeams[teamIndex][questionKey] = value;
            setTeams(updatedTeams);
          };

    return (
        <div className="manual-score-container">
      <div className="manual-score-header">
        <h2>Manual Scores</h2>
        <button className="save-button" onClick={updateScores}>
          SAVE ALL
        </button>
      </div>
      <table className="manual-score-table">
        <thead>
          <tr>
            <th>TEAM NAME</th>
            <th>RELAY <span className="max-score">(Max 600)</span></th>
            <th>EMOJI <span className="max-score">(Max 600)</span></th>
            <th>ELVES <span className="max-score">(Max 600)</span></th>
            <th>SPACE <span className="max-score">(Max 1000)</span></th>
            <th>SCORE ADJUSTMENT <span className="max-score">(±10K)</span></th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team, index) => (
            <tr key={team.team_name}>
              <td>{team.team_name}</td>
              {['q1_score', 'q2_score', 'q3_score', 'q4_score','q5_score'].map((questionKey, idx) => {
                let min = 0;
                let max = 300;
                if (idx === 4 || idx === 0) {
                  min = -10000;
                  max = 10000;
                }
                return (
                  <td key={questionKey}>
                    <input
                      type="number"
                      value={team[questionKey] !== null ? team[questionKey] : 0}
                      min={min}
                      max={max}
                      onChange={(e) => handleScoreChange(index, questionKey, e.target.value)}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        );
}

export default ManualScore;