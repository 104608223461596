import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';


function ResetData({API_URL,accessToken}) {
    const [teamName, setTeamName] = useState('');

    const resetData = async () => {
      const url = teamName ? `${API_URL}/reset_rankings?team_name=${encodeURIComponent(teamName)}` : `${API_URL}/reset_rankings`;
        
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            toast.success(data.message, {
                position: "top-center"
            });
          } else if (data.status === "failed") {
            toast.error(data.message, {
                position: "top-center"
            });
          }
        })
        .catch(error => {
            console.error('Error:', error)
            toast.error("Could not reset data", {
                position: "top-center"
            });
        });
        
    }

    return(
        <div>
        <h1>Team Name (optional):</h1>
        <input
            type="text"
            placeholder="Enter team name or leave blank for all"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)} />
    
        <button onClick={resetData}>Reset Rankings</button>
        </div>
    )
}

export default ResetData;