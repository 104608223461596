import './css/App.css';
import Navbar from './Navbar';
import TimeCapsule from './TimeCapsule/TimeCapsule';
import VCCHome from './VCC/VCCHome';
import HomePage from './HomePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TimeCapsuleSingle from './TimeCapsule/TimeCapsuleSingle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Question1 from './Questions/Question1'; // Import the Question1 component
import Question2 from './Questions/Question2'; // Import the Question1 component
import Question3 from './Questions/Question3'; // Import the Question1 component

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar/>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="TimeCapsule" element={<TimeCapsuleSingle/>} />
          <Route path="/VCC/*" element={<VCCHome/>} />
          <Route path="/urf/jrq/question1" element={<Question1 />} />
          <Route path="/urf/jrq/question2" element={<Question2 />} />
          <Route path="/urf/jrq/question3" element={<Question3 />} />
        </Routes>
        <ToastContainer  className="toast-container" />
      </div>
    </BrowserRouter>
  );
}

export default App;