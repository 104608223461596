import React from 'react';
//import './css/tutorial.css'
import PureMarkdown from '../VCC/Utilities/PureMarkdown';


const content = `
# Spaceship Navigation Challenge

## Overview
Your task is to create an autopilot system for a spaceship that can navigate through a series of increasingly difficult levels. Each level contains a number of planets that the spaceship must avoid while reaching its target destination.

## Objective
Modify the \`autopilot.py\` file to create an AI that can successfully navigate the spaceship through all 5 levels without crashing into any planets or running out of fuel.

## Game Mechanics
- The game consists of 5 levels, each with more planets than the last.
- The spaceship starts on the left side of the screen and must reach the target on the right side.
- The spaceship has limited fuel, which depletes when thrusting.
- Colliding with a planet results in game over.
- Gravity from planets affects the spaceship's trajectory.

## Your Task
Implement the \`make_decision\` method in the \`Autopilot\` class within \`autopilot.py\`. This method should return two values:
1. \`rotation\`: The angle to rotate the ship (between -3 and 3 degrees per frame)
2. \`thrust\`: A boolean indicating whether to apply thrust (True) or not (False)

## Available Variables and Methods

### Autopilot Class
- \`self.ship\`: The spaceship object
- \`self.planets\`: List of Planet objects in the current level
- \`self.target\`: The target object to reach

#### Methods:
- \`direction_to_goal()\`: Returns the angle (in degrees) to the target
- \`direction_to_nearest_planet()\`: Returns the angle to the nearest planet
- \`distance_to_nearest_planet()\`: Returns the distance to the nearest planet
- \`distance_to_goal()\`: Returns the distance to the target
- \`distance_to_planet(planet)\`: Returns the distance to a specific planet

### Ship Class
- \`pos\`: [x, y] position of the ship
- \`angle\`: Current angle of the ship (in degrees)
- \`speed\`: [dx, dy] current speed vector
- \`fuel\`: Remaining fuel (starts at 1000, decreases by 1 for each frame of thrust)

### Planet Class
- \`pos\`: [x, y] position of the planet
- \`radius\`: Radius of the planet
- \`mass\`: Mass of the planet (affects gravity)

### Target Class
- \`pos\`: [x, y] position of the target

## Constraints
- Your autopilot must work for all 5 levels without modification between levels.
- The spaceship must reach the target without colliding with any planets.

## Tips
- Most simple solutions make good use of existing methods int he Autopilot class
- Speed makes the ship harder to control, consider moving slowly or even using up some fuel to slow down
- You do not crash or break any rules when hitting the edge of the map
- Here is what the 360 degrees of the \`angle\` variable mean
[Spaceship Pilot Degrees 360](/files/degrees-360.svg)

## Marking
You get **60 points** for each level, simply show your solution pressing SPACE between levels

## Starter Code
Download the zip file and unzip it:[\`spaceship_pilot.zip\`](/files/questions/q3/spaceship_pilot.zip)
Then open the autopilot.py file and start coding!
`;

const Question3 = () => {

  return (
    <PureMarkdown content={content}/>
  );
}

export default Question3;