import React from 'react';
//import './css/tutorial.css'
import PureMarkdown from '../VCC/Utilities/PureMarkdown';

const content = `
# Elf Factory Optimization Challenge

## Background
The North Pole's toy factory is gearing up for the holiday season, and Santa needs your help to optimize the production line! You'll be working with three skilled elves to create toys as efficiently as possible.

## Part 1: Basic Optimization (150 points)
Create a program that produces an optimal schedule allowing elves to make at least 14 toys in a 12-hour shift.

### Requirements
- Use exactly 3 elves with the following skill levels:
  - Speedy: 1.5x speed
  - Missy Flash: 1.0x speed
  - Joey: 0.9x speed
- Each elf can only work on one toy at a time
- No elf can work more than 12 hours
- The solution should maximise the number of toys produced
- Output must match the format shown below

### Example Output Format
\`\`\`text
Elf Schedules:

Speedy:
- Truck: 2.00 hours
- Dollhouse: 6.00 hours
- Phone: 1.33 hours
- Fishing Rod: 2.00 hours
Total hours for Speedy: 11.33

Missy Flash:
- Helicopter: 4.00 hours
. . . continued for all elfs . . .

Total toys produced: 9
\`\`\`

## Part 2: Schedule with Time of Day (75 points)
Modify your solution to show the start and end times for each toy in 24-hour format.

### Example Output Format
\`\`\`text
Elf Schedules:

Speedy:
- Dollhouse (00:00 - 12:51)
- Microscope (12:51 - 21:25)

Missy Flash:
- Kitchen (00:00 - 07:16)
- Robot Dog (07:16 - 13:38)
\`\`\`

## Part 3: Adding Breaks (75 points)
The elf union has won each elf two 90-minute breaks in a 12-hour shift. Modify your solution to:
- Include exactly two breaks per elf
- Breaks must be separate (not combined into one 3-hour break)
- Show breaks in the schedule
- Maximise toys produced with these new constraints

### Example Output Format
\`\`\`text
Total toys produced: X

Elf Schedules:

Speedy:
- Dollhouse (00:00 - 06:00)
- Break (06:00 - 07:30)
- Train (07:30 - 10:30)
- Break (10:30 - 12:00)
\`\`\`

## Starter Code
\`\`\`python
def create_toy_list():
    """
    Returns a list of tuples containing (toy_name, production_hours)
    Production hours are for an elf with 1.0 speed multiplier
    """
    return [
        ("Medical Kit", 3), ("Teddy Bear", 4), ("Castle", 8),
        ("Phone", 2), ("Dragon", 6), ("Piano", 5),
        ("Helicopter", 4), ("Farm Set", 7), ("Puzzle", 2),
        ("Tool Set", 4), ("Shield", 3), ("Kitchen", 8),
        ("Submarine", 5), ("Sword", 3), ("Robot Dog", 7),
        ("Guitar", 4), ("Boat", 3), ("Cash Register", 4),
        ("Race Track", 7), ("Zoo Set", 6), ("Truck", 3),
        ("Board Game", 5), ("Action Figure", 4), ("Train", 6),
        ("Cat", 3), ("Computer", 5), ("Telescope", 7),
        ("Unicorn", 5), ("Binoculars", 2), ("Bulldozer", 5),
        ("Horse", 4), ("Dollhouse", 9), ("Drum", 4),
        ("Rocket", 5), ("Armor Set", 6), ("Car", 3),
        ("Robot", 6), ("Airplane", 4), ("Wizard Wand", 2),
        ("Bow and Arrow", 3), ("Microscope", 6), ("Stuffed Animal", 3),
        ("Walkie-Talkie", 3), ("Lego Set", 7), ("Dinosaur", 4),
        ("Doll", 5)
    ]

def create_elf_list():
    """
    Returns a list of tuples containing (elf_name, speed_multiplier)
    """
    return [
        ("Speedy", 1.5),      # 50% faster than base speed
        ("Missy Flash", 1.0), # Works at base speed
        ("Joey", 0.9)         # 10% slower than base speed
    ]

# Your solution should start here
def create_schedule():
    toys = create_toy_list()
    elves = create_elf_list()
    
    # Your code goes here
    
    # Example of expected output format:
    print("\\nElf Schedules:\\n")
    print("Speedy:")
    print("- Truck: 2.00 hours")
    print("Total hours for Speedy: 2.00")
    print("\\nTotal toys produced: 1")

if __name__ == "__main__":
    create_schedule()
\`\`\`

## Submission Requirements
- Your solution must run without modifying the toy list or elf list and each toy can only be produced once
- All scheduling logic must be in the \`create_schedule()\` function
- Output must exactly match the format shown in examples
- For Parts 2 and 3, create new functions \`create_schedule_with_time()\` and \`create_schedule_with_breaks()\`

## Scoring
- Part 1: 150 points
  - 50 pts for 10 or more toys
  - 100 pts for 14 or more toys
  - 150 pts for 15 or more toys
  - Must maintain correct format
  - All elves must work ≤ 12 hours
- Part 2: 75 points
  - Correct time format
  - Valid schedule
- Part 3: 75 points 
  - Correct break implementation
  - Maximum toys with breaks
  - Valid schedule

Good luck! 🎄🧝‍♂️
`;

const Question2 = () => {
  return (
    <PureMarkdown content={content}/>
  );
}

export default Question2;