import React, { useState, useEffect } from 'react';
import './css/quiz.css'
import { gotoQuestion } from '../../slices/questionSlice';
import { useDispatch, useSelector } from 'react-redux';

const groupColors = {
        "1": "#FF5733",  // Bright Orange
        "2": "#56155c",  // Bright Green
        "3": "#3357FF",  // Bright Blue
        "4": "#FF33A1",  // Bright Pink
        "5": "#808701",  // Bright Yellow
        "6": "#30377c",  // Bright Cyan
        "7": "#A833FF",  // Bright Purple
        "9": "#2A2ACF", "10": "#4E4EEE", "11": "#2A2AD2", "12": "#7710F4",
        "13": "#C460C4", "14": "#A432A4", "15": "#C44EC4", "16": "#F410F4",
        "17": "#A410A4", "18": "#E900E9", "19": "#920092", "20": "#CE0BCE",
        "21": "#EE0000", "22": "#A52929", "23": "#EA2020", "24": "#D54444"
    };

const QuestionTable = ({questions}) => {
    const dispatch = useDispatch();
    return (
        <div id="questionNavContainer">
        <table id="questionNav">
            <tbody>
            <tr>
                {questions.map((question, index) => (
                <td key={index}>
                    <button
                    style={{
                        backgroundColor: question.answerstatus === 'correct' ? 'palegreen' :
                                        question.answerstatus === 'incorrect' ? 'grey' :
                                        question.solved === 1 ? 'palegreen' :
                                        question.solved === 0 && question.attempt_count === 1 && (question.type === 'mcqs' || question.type=== 'img_mcqs')? 'grey' :
                                        question.solved === 0 && question.attempt_count === 3 && question.type === 'short answer' ? 'grey' :
                                        groupColors[question.question_group] || '#4CAF50',
                        color: 'white'
                    
                    }}
                    onClick={() => dispatch(gotoQuestion(index))}>
                    {index + 1}
                    </button>
                </td>
                ))}
            </tr>
            </tbody>
        </table>
        </div>
    )
}

export default QuestionTable;