import React from 'react';
import './css/Navbar.css'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Navbar() {
  const location = useLocation();

  // Don't display the navbar on the independent page
  if (location.pathname === '/TimeCapsule') {
    return null;
  }
    return (
      <nav className="navbar">
        <Link to="/">HOME</Link>
        <Link to="/VCC/VCC2023">VCC</Link>
        <Link to="/TimeCapsule">Time Capsule</Link>
  </nav>
    );
  }

export default Navbar;