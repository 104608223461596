import React, { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import './css/TimeCapsule.css'

const API_URL = process.env.REACT_APP_TIME_CAP_API_URL

function TimeCapsuleSingle() {
  const [userInput, setUserInput] = useState('');
  const [response, setResponse] = useState({
      message: '',
      messageContent: '',
      timeCreated: '',
      timeRevealed: '',
      lockedBy: '',
      encryptionMethod: '',
      userKey: '',
    });  
  
  
  useEffect(() => {
      // Set body styles on mount
      document.body.style.backgroundColor = '#f0f0f5';
  
      // Revert styles on unmount
      return () => {
        document.body.style.backgroundColor = '';
      };
    }, []);
    
  
    const handleSubmit = async () => {

      if (!userInput.trim()) {
        toast.error('Please Enter all the fields', {
          position: "top-center"
        });
        return;
      }

      fetch(`${API_URL}/submit-capsule`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: userInput })
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          setResponse({
            message: data.data.message,
            messageContent: data.data.messageContent,
            timeCreated: data.data.time_created,
            timeRevealed: data.data.time_revealed,
            lockedBy: data.data.locked_by,
            encryptionMethod: data.data.encryption_method,
            userKey: data.data.user_key,
          });
          toast.success(data.message, {
            position: "top-center"
          });
        } else if (data.status === "failed") {
          toast.error(data.message, {
            position: "top-center"
          })
        }
      })
      .catch(error => {
        console.error('Failed to submit team:', error);
        toast.error(`Failed to submit team`, {
          position: "top-center"
        });
      });
  
    };
  
    return(
        <div className="container">
        <h1>Time Capsule Challenge</h1>
        <div className="instructions">
            <p>Use this program to create a time capsule: <a href="https://trinket.io/python3/cb0c43fc99" target="_blank">Capsule Maker</a></p>
            <p>Enter the encrypted time capsule message in the text area below and click the "Submit" button to decode it.</p>
        </div>
        <textarea id="userInput"
        placeholder="Enter your time capsule here"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}></textarea>
        <button id="submitButton" onClick={handleSubmit}>Submit!</button>
        <div id="response">
        <p><strong>Message:</strong> <span>{response.message}</span></p>
            <pre>{response.messageContent}</pre>
            <p><strong>Time Created:</strong> <span>{response.timeCreated}</span></p>
            <p><strong>Time Revealed:</strong> <span>{response.timeRevealed}</span></p>
            <p><strong>Locked By:</strong> <span>{response.lockedBy}</span></p>
            <p><strong>Encryption Method:</strong> <span>{response.encryptionMethod}</span></p>
            <p><strong>User Key:</strong> <span>{response.userKey}</span></p>
        </div>
    </div>

);
}
export default TimeCapsuleSingle;