import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { checkTokenExpiry } from '../../slices/authSlice';
import { Editor } from '@monaco-editor/react';
import './css/rankings.css';

const UpdateQuestions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const API_URL = useSelector((state) => state.auth.ApiUrl);
  const token = useSelector((state) => state.auth.token);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [jsonData, setJsonData] = useState('');

  useEffect(() => {
    const tokenExpired = dispatch(checkTokenExpiry());
    if (!isAuthenticated || currentUser.role !== "admin" || tokenExpired) {
      // Redirect to the home page if not authenticated
      navigate('/VCC/AdminLogin');
    }else{
        fetch(`${API_URL}/current_json`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            })
              .then(response => response.json())
              .then(data => {
                if (data.status === "success") {
                    setJsonData(JSON.stringify(data.data, null, 2));
                } else if (data.status === "failed") {
                  toast.error(data.message, {
                      position: "top-center"
                  });
                }
              })
              .catch(error => {
                  console.log(error);
                  toast.error(`Failed to get data`, {
                      position: "top-center"
                  });
              });
      }
      
    }, [navigate]);

    const handleSave = () => {
        let updatedJson;
        try {
            updatedJson = JSON.parse(jsonData);
        } catch (e) {
            toast.error('Invalid JSON format', {
            position: 'top-center',
            });
            return;
        }
        
        fetch(`${API_URL}/update_json`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedJson),
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.status === 'success') {
            toast.success('JSON updated successfully!', {
                position: 'top-center',
            });
            } else if (data.status === "failed"){
            toast.error(data.message, {
                position: 'top-center',
            });
            }
        })
        .catch((error) => {
            console.error(error);
            toast.error('Failed to save data', {
            position: 'top-center',
            });
        });
    
    };
    const handleEditorChange = (value) => {
        setJsonData(value);
      };

    return (
        <div className="update-questions-container">
      <h2>Edit JSON Data</h2>
      
      <Editor
        height="700px"
        width="900px"
        defaultLanguage="json"
        value={jsonData}
        onChange={handleEditorChange}
        theme="vs-dark" // Set to dark theme
        options={{
          automaticLayout: true,
        }}
      />
      <button onClick={handleSave}>Save</button>
    </div>
    );

};

export default UpdateQuestions;