import React, { useState, useEffect } from 'react';
import './css/quiz.css'
import PureMarkdown from '../Utilities/PureMarkdown';
import { useDispatch, useSelector } from 'react-redux';
import { setScore, setAnswerStatus, setAttempts, setUserAnswer } from '../../slices/questionSlice';
import { toast } from 'react-toastify';

const ShortQuestion = ({API_URL, token, question}) => {
    const dispatch = useDispatch();
    const userAnswers = useSelector((state) => state.questions.answers); // Access stored answers
  
    
    const handleAnswerChange = (e) => {
        dispatch(setUserAnswer({
          questionId: question.id,
          answer: e.target.value,
        }));
      };

    const fetchWithTimeout = (url, options, timeout = 5000) => {
    return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Timeout')), timeout)
    ),
    ]);
    };
    const submitAnswer = () => {
        
        fetchWithTimeout(`${API_URL}/submit_sa_answer`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              id: question.id.toString(),
              answer: userAnswers[question.id].trim(),
            }),
          }, 5000) // Timeout set to 5000 milliseconds (5 seconds)
            .then(response => response.json())
            .then(data => {
              if (data.status === "success" && data.data.includes("No attempts left")) {
                alert("Out of tries");
                dispatch(setAnswerStatus('incorrect'));
              } else if (data.status === "success" && data.data.includes("Correct")) {
                dispatch(setScore(parseInt(question.current_points)));
                dispatch(setAnswerStatus('correct'));
                dispatch(setAttempts(1));
              } else if (data.status === "success" && data.data.includes("Incorrect")) {
                dispatch(setAnswerStatus('incorrect'));
                dispatch(setAttempts(1));
              } else if (data.status === "success" && data.data.includes("Try again")) {
                dispatch(setAnswerStatus('try-again'));
                dispatch(setAttempts(1));
              } else if (data.status === "failed") {
                alert(data.message);
              }
            })
            .catch(error => {
              if (error.message === 'Timeout') {
                toast.error('Request timed out. Please try again.', {
                  position: 'top-right',
                });
              } else {
                console.log("Error submitting answer:", error);
                toast.error('An error occurred. Please try again.', {
                  position: 'top-right',
                });
              }
            });
            
    }
     return(
        <div className="question-container">
            <h3>{question.id}. {question.title}</h3>
            <b>For {question.current_points} pts:</b>
            <PureMarkdown content={question.content} />
            {question.content_link && (
                <a href={`${process.env.PUBLIC_URL}${question.content_link}`} target="_blank" rel="noopener noreferrer">{question.content_link}</a>
            )}
            {question.image_link && (
            <img src={`${process.env.PUBLIC_URL}${question.image_link}`} alt="Question" style={{ width: '100%', display: question.image_link ? 'block' : 'none', marginBottom: '20px' }} />
            )}
            <div>
            
            <input type="text" 
                    placeholder="Your answer" 
                    value={userAnswers[question.id] || ''} 
                    disabled={question.attempt_count >= 3} 
                    onChange={handleAnswerChange} />
        
            </div>
            <button style={{
                backgroundColor: question.answerstatus === 'correct' ? 'palegreen' :
                question.answerstatus === 'incorrect' ? 'grey' :
                question.solved === 1 ? 'palegreen': 
                question.solved === 0 && question.attempt_count === 3 ? 'grey' :
                '#4CAF50'
            }}
            disabled={question.solved === 1 ||  question.attempt_count === 3}
            onClick={submitAnswer}>Submit</button>
            <h3 id="attempts">Attempts: {question.attempt_count}/3</h3>
        </div>
    )

}

export default ShortQuestion;