import React, { useState, useEffect } from 'react';
import './css/quiz.css'
import PureMarkdown from '../Utilities/PureMarkdown';
import { useDispatch, useSelector } from 'react-redux';
import { setScore, setAnswerStatus, setAttempts, setUserAnswer } from '../../slices/questionSlice';
import { toast } from 'react-toastify';

const McqsQuestion = ({API_URL, token, question}) => {
    const dispatch = useDispatch();
    const userAnswers = useSelector((state) => state.questions.answers);
    const [copied, setCopied] = useState(false);

    const q_16=`# List of 10 most common stop words to exclude
stop_words = ['the', 'and', 'to', 'of', 'a', 'in', 'that', 'is', 'was', 'it']

# Place the file in the same folder as your python program
# Then open the file with UTF-8 encoding
with open('pride_and_prejudice.txt', 'r', encoding='utf-8') as file:
    text = file.read().lower()
    
# Print the first 500 characters to verify the content
print(text[:500])`

    const q_27=`import random

def prisoner_strategy(prisoner_number, available_doors):
    """
    Implement the strategy for a single prisoner trying to escape.
    
    :param prisoner_number: The number of the current prisoner (0-7)
    :param available_doors: A set of door numbers that are still available
    :return: A list of door numbers the prisoner will try (max 5)
    """
    # TODO: Implement your strategy here
    # Remember: 
    # - You can try up to 5 doors
    # - available_doors is a set of integers representing available door numbers
    # - Return a list of door numbers to try (must be a subset of available_doors)
    pass

def simulate_prison_escape(simulations=100000):
    success_count = 0

    for _ in range(simulations):
        exit_doors = list(range(8))
        random.shuffle(exit_doors)
        available_doors = set(range(8))
        all_prisoners_succeeded = True

        for prisoner_number in range(8):
            doors_to_try = prisoner_strategy(prisoner_number, available_doors)
            
            if len(doors_to_try) > 5 or not set(doors_to_try).issubset(available_doors):
                raise ValueError("Invalid strategy: too many doors or unavailable doors selected")

            if exit_doors[prisoner_number] in doors_to_try:
                if exit_doors[prisoner_number] == prisoner_number:
                    available_doors.discard(prisoner_number)
            else:
                all_prisoners_succeeded = False
                break

        if all_prisoners_succeeded:
            success_count += 1

    probability = success_count / simulations
    print(f"The approximate probability of all prisoners escaping is: {probability}")

simulate_prison_escape()
    `
    const copyToClipboard = () => {
        let codeToCopy = '';
        if (question.id === 16) {
            codeToCopy = q_16;
        }
        else if (question.id === 27) {
            codeToCopy = q_27;
        }
        navigator.clipboard.writeText(codeToCopy)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
            })    
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    const handleAnswerChange = (e) => {
        dispatch(setUserAnswer({
          questionId: question.id,
          answer: e.target.value,
        }));
      };

    const fetchWithTimeout = (url, options, timeout = 5000) => {
    return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Timeout')), timeout)
    ),
    ]);
    };

    const submitAnswer = () => {
        fetchWithTimeout(`${API_URL}/submit_mcqs_answer`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
            id: question.id.toString(),
            answer: userAnswers[question.id],
            }),
        }, 5000)
            .then(response => response.json())
            .then(data => {
            if (data.status === 'success' && data.data.includes('Correct')) {
                dispatch(setScore(parseInt(question.current_points)));
                dispatch(setAnswerStatus('correct'));
            } else if (data.status === 'success' && data.data.includes('Incorrect')) {
                dispatch(setAnswerStatus('incorrect'));
            } else if (data.status === 'failed') {
                alert(data.message);
            }
            })
            .catch(error => {
            if (error.message === 'Timeout') {
                toast.error('Request timed out. Please try again.', {
                position: 'top-right',
                });
            } else {
                toast.error('Could not submit answer. Please submit again.', {
                position: 'top-right',
                });
            }
            console.error('Error submitting answer:', error);
            });
          
    };
    
    

    return(
        
        <div className="question-container">
            <h3>{question.id}. {question.title}</h3>
            <b>For {question.current_points} pts:</b>
            {question.id === 16 || question.id === 27 ? (
                <button className="button-copy" onClick={copyToClipboard}>Copy Code</button>
            ):null}
            <PureMarkdown content={question.content}/>
            {question.content_link && (
                <a href={`${process.env.PUBLIC_URL}${question.content_link}`} target="_blank" rel="noopener noreferrer">{question.content_link}</a>
            )}
            {question.image_link && (
            <img src={`${process.env.PUBLIC_URL}${question.image_link}`} alt="Question" style={{ width: '100%', display: question.image_link ? 'block' : 'none', marginBottom: '20px' }} />
            )}
            <div>
                {question.options.map((option, index) => (
                    <label className="option-label normal-font-weight" key={index}>
                        <input
                            type="radio"
                            name="option"
                            value={String.fromCharCode(97 + index)}
                            checked={userAnswers[question.id] === String.fromCharCode(97 + index)}
                            onChange={handleAnswerChange}
                        /> {<span className="option-text">
                            <PureMarkdown content={option} noPadding />
                            </span>}
                    </label>
                ))}
                
            </div>
            <button style={{
                backgroundColor: question.answerstatus === 'correct' ? 'palegreen' :
                question.answerstatus === 'incorrect' ? 'grey' :
                question.solved === 1 ? 'palegreen':
                question.solved === 0 ? 'grey' : '#4CAF50'}}
                disabled={question.solved === 1}
            onClick={submitAnswer}>Submit</button>
        </div>
    )

}

export default McqsQuestion;