import React from 'react';
import './css/tutorial.css'
import PureMarkdown from './PureMarkdown';

const content=`
# API Access Tutorial

This tutorial will guide you through the process of accessing APIs using Python and the requests library.

## Prerequisites:
Before starting, you should have a basic understanding of Python programming. Ensure Python 3.x is installed on your system, as well as the \`requests\` library, which can be installed using pip with the command:

\`\`\`bash
pip install requests
\`\`\`

## Step 1: Import and Make a GET Request

\`\`\`python
import requests
response = requests.get('https://jsonplaceholder.typicode.com/posts')
\`\`\`

## Step 2: Check the Response Status

\`\`\`python
import requests
response = requests.get('https://jsonplaceholder.typicode.com/posts')
if response.status_code == 200:
    print("Success!")
else:
    print("An error has occurred.")
\`\`\`

Your python terminal will print the word: **"Success!"** if the API request has been received and processed.

## Step 3: Access and Work with the Data

Please note, \`response.json()\` returns a Python dictionary that stores all the data which is in the response. You can use dictionary operations to extract the data and perform various operations on it.

\`\`\`python
import requests
response = requests.get('https://jsonplaceholder.typicode.com/posts')
if response.status_code == 200:
    # Parsing as JSON
    for post in response.json():
        print(post['title'])
else:
    print("An error has occurred.")
\`\`\`

Run this program in your Python environment, then open this link in a new tab: [https://jsonplaceholder.typicode.com/posts](https://jsonplaceholder.typicode.com/posts)

Observe how the program is processing the JSON blob and extracting the title from each post.


`


const Tutorial = () => {

  return (
    <PureMarkdown content={content}/>
  );
}

export default Tutorial;