import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';


function ResetQuestion({API_URL,accessToken}) {

    const resetQuestion = async () => {
        fetch(`${API_URL}/reset_questions_score`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify({}),
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            toast.success(data.message, {
                position: "top-center"
            });
          } else if (data.status === "failed") {
            toast.error(data.message, {
                position: "top-center"
            });
          }
        })
        .catch(error => {
            console.error('Error:', error)
            toast.error("Could not reset questions", {
                position: "top-center"
            });
        });
        
    }

    return(
        <div>
        <button onClick={resetQuestion}>Reset Questions</button>
        </div>
    )

}

export default ResetQuestion;