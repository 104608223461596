import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import questionsReducer from './slices/questionSlice';

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('reduxState', serializedState); // Use sessionStorage instead of localStorage
  } catch (e) {
    console.warn('Error saving state to session storage:', e);
  }
};

const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('reduxState'); // Use sessionStorage instead of localStorage
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn('Error loading state from session storage:', e);
    return undefined;
  }
};

const persistedState = loadState();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    questions: questionsReducer,
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveState(store.getState());
});