import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

function UploadFile({API_URL,accessToken}) {

    
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append('file', file);
        fetch(`${API_URL}/upload`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          },
          body: formData,
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            toast.success(data.message, {
                position: "top-center"
            });
          } else if (data.status === "failed") {
            toast.error(data.message, {
                position: "top-center"
            });
          }
        })
        .catch(error => {
            console.error('Error:', error)
            toast.error("Could not upload file", {
                position: "top-center"
            });
        });
        
    }

    return(
        <div>
            <input
                type="file"
                id="uploadFile"
                accept=".json"
                onChange={handleFileChange} />
            <button onClick={uploadFile}>Upload JSON</button>
        </div>
    )
}

export default UploadFile;