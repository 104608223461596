import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

function SetJson({API_URL,accessToken}) {

    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');

    useEffect(() => {
        fetchJsonFiles();
    }, []);

    const fetchJsonFiles = async () => {
        fetch(`${API_URL}/json-files`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            setFiles(data.data);
          } else if (data.status === "failed") {
            toast.error(data.message, {
                position: "top-center"
            });
          }
        })
        .catch(error => {
            console.error('Error:', error)
            toast.error("Could not set JSON", {
                position: "top-center"
            });
        });
        
    }
    const setDataType = async () => {
        if (files.length > 0 && selectedFile) {
            fetch(`${API_URL}/set_json`, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
              })
              .then(response => response.json())
              .then(data => {
                if (data.status === "success") {
                  setFiles(data.data);
                } else if (data.status === "failed") {
                  toast.error(data.message, {
                      position: "top-center"
                  });
                }
              })
              .catch(error => {
                  console.error('Error:', error)
                  toast.error("Could not set JSON", {
                      position: "top-center"
                  });
              });
        }
    }
    const handleFileChange = (e) => {
        setSelectedFile(e.target.value);
      };

    return(
        <div className="json-file-selector">
      <div style={{ marginTop: '20px' }}>
        <label htmlFor="dataDropdown">Choose Data:</label>
        <select
          id="dataDropdown"
          value={selectedFile}
          onChange={handleFileChange}
        >
          {files.map((file, index) => (
            <option key={index} value={file}>
              {file}
            </option>
          ))}
        </select>
      </div>
      <div style={{ marginTop: '20px' }}>
        <button onClick={setDataType}>Set Selected Json</button>
      </div>
    </div>
        
    )
}

export default SetJson;