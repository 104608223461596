import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  team_name: '',
  questions: [],
  currentQuestionIndex: 0,
  currentQuestion: null,
  score: 0,
  answers: []
}

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload.map(questions => ({
        ...questions,
        answerstatus: null    
      }));
      state.currentQuestionIndex = 0; 
      state.currentQuestion = state.questions[0];
    },
    nextQuestion: (state) => {
        if (state.currentQuestionIndex < state.questions.length - 1) {
          state.currentQuestionIndex += 1;
          state.currentQuestion = state.questions[state.currentQuestionIndex];
        }
      },
  
      // Action to navigate to the previous question
      previousQuestion: (state) => {
        if (state.currentQuestionIndex > 0) {
          state.currentQuestionIndex -= 1;
          state.currentQuestion = state.questions[state.currentQuestionIndex];
        }
      },
      gotoQuestion: (state,action) => {
          const index = action.payload;
          state.currentQuestionIndex = index;
          state.currentQuestion = state.questions[index];
      },
  
      // Action to set the current question directly (e.g., from a button)
      setCurrentQuestion: (state, action) => {
        const index = action.payload;
        if (index >= 0 && index < state.questions.length) {
            state.currentQuestionIndex = index;
            state.currentQuestion = state.questions[index];
        }
      },
      setAttempts: (state, action) => {
        state.currentQuestion.attempt_count += action.payload;
        state.questions[state.currentQuestionIndex].attempt_count += action.payload;
      },
      setAnswerStatus: (state, action) => {
        state.currentQuestion.answerstatus = action.payload;
        state.questions[state.currentQuestionIndex].answerstatus = action.payload;
        if (action.payload === "correct"){
          state.currentQuestion.solved = 1;
          state.questions[state.currentQuestionIndex].solved = 1;
        
        }else if (action.payload === "incorrect"){
          state.currentQuestion.solved = 0;
          state.questions[state.currentQuestionIndex].solved = 0;
        
        }   
      },
      setScore: (state, action) => {
        state.score += action.payload;
        
      },
      setUserAnswer: (state, action) => {
        const { questionId, answer } = action.payload;
        state.answers[questionId] = answer; // Store answer keyed by question ID
      },
  },
});

export const { setQuestions, nextQuestion, previousQuestion, setCurrentQuestion, setScore, setAttempts, setAnswerStatus, gotoQuestion, setUserAnswer } = questionsSlice.actions;
export default questionsSlice.reducer;