import './css/App.css';
import React from 'react';
import PureMarkdown from './VCC/Utilities/PureMarkdown';


const content=`
# Welcome to the Main Challenge!

## How to Participate

1. Wait for the main challenge to start! (starting early will be penalized -200 points)
2. **[Login to Start the Quiz](/VCC/Login)** with your username and password

## Competition Rules

- The main challenge is time-limited, so be prepared before you start.
- Choose your questions carefully, you will not have enough time to do all of them
---

**Ready to take on the challenge?**  
👉 **[Click here to login and get started](/VCC/Login)** 👈

---

Good luck, and happy coding!

---
`

function HomePage() {
  return (
    <PureMarkdown content={content}/>

  );
}

export default HomePage;